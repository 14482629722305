<template>
  <v-container>
    <v-card>
      <v-card-title>
        {{$t('settings.lang_cashRecyclerSettings')}}
      </v-card-title>
      <v-divider/>
      <v-card-text>
        <v-form lazy-validation ref="cashRecyclerSettingsForm" v-model="valid">
          <v-row>
            <v-col cols="12" sm="6">
              <v-text-field
                          @focus="showTouchKeyboard"
                          :data-layout="KEYBOARD.KEYSETS.NORMAL" v-model="alias" outlined  :rules="[rules.required]" aria-autocomplete="false"
                          :label="$t('generic.lang_alias')" ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                          @focus="showTouchKeyboard"
                          :data-layout="KEYBOARD.KEYSETS.NORMAL" v-model="ip_address" outlined  :rules="[rules.ipAddress]" aria-autocomplete="false"
                          :label="$t('settings.lang_ipOfCashRecycler')"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                  @focus="showTouchKeyboard"
                  :data-layout="KEYBOARD.KEYSETS.NORMAL"
                  v-model="cash_recycler_user" :disabled="loading"
                  outlined
                  :label="$t('settings.lang_cashDroUser')">
              </v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                          @focus="showTouchKeyboard"
                          :data-layout="KEYBOARD.KEYSETS.NUMERIC" type="password" v-model="cash_recycler_password" :rules="[rules.required]" outlined aria-autocomplete="false"
                          :label="$t('generic.lang_password')"></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <v-select v-model="cashier_id" :items="cashierIDS" :rules="[rules.required]" :disabled="loading" outlined  item-text="name" item-value="id"
                        :label="$t('generic.lang_cashierID')">

              </v-select>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions :class="{'d-block':$vuetify.breakpoint.xs,'text-right':true}">

        <v-spacer/>
        <v-btn color="success" :disabled="loading || !valid" :loading="loading" @click="createCashRecyclerSettings">{{
            $t('generic.lang_save')
          }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard class="internalWidth" id="onScreenKeyboard" :options="touchKeyboard.options" :defaultKeySet="touchKeyboard.keySet" v-if="touchKeyboard.visible" :layout="touchKeyboard.layout" :cancel="hideTouchKeyboard"
                          :accept="hideTouchKeyboard" :input="touchKeyboard.input"/>
    </div>
  </v-container>
</template>

<script>
import {mapState} from "vuex";
import {ENDPOINTS} from "@/config";
import {Events} from "@/plugins/events";
import validation from "@/mixins/validation/validation";
import mixin from '@/mixins/KeyboardMixIns'

export default {
  name: "CreateCashRecyclerSettingsComponent",
  mixins:[validation,mixin],
  data() {
    return {
      loading: false,
      alias:null,
      ip_address:null,
      cash_recycler_user:null,
      cashier_id:null,
      cash_recycler_password:null,
      valid:true,
    }
  },
  computed:{
    ...mapState([
      "cashierIDs",
    ]),
    cashierIDS(){
      return this.cashierIDs.availableCashierIDs.map(cashier=> {return {id:cashier,name:'Kasse '+cashier}})
    },
  },
  methods: {
    createCashRecyclerSettings() {

      if(!this.$refs.cashRecyclerSettingsForm.validate()){
        return;
      }

      this.loading = true;

      this.axios.post(ENDPOINTS.SETTINGS.MODULESETTINGS.CASHRECYCLER.CREATE, {
        alias: this.alias,
        ip_address: this.ip_address,
        cash_recycler_user: this.cash_recycler_user,
        cashier_id: this.cashier_id,
        cash_recycler_password: this.cash_recycler_password,
      }).then((res) => {
        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_actionSuccessful'),
            color: "success"
          });
          this.$refs.cashRecyclerSettingsForm.reset();
        } else {
          Events.$emit("showSnackbar", {
            message: res.data.msg || this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(err=>{
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred')+' : '+err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      });
    },
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>