<template>
  <div>
    <page-title :heading="$t('settings.lang_cashRecyclerSettings')" :subheading="$t('generic.lang_create') " :icon=icon
                :show-previous-button="true"></page-title>
    <div class="app-main__inner">
      <CreateCashDrawerSettings/>
    </div>
  </div>
</template>

<script>
import PageTitle from "../../../../Layout/Components/PageTitle.vue";
import CreateCashDrawerSettings from "../../../../components/settings/moduleSettings/cashrecycler/CreateCashRecyclerSettingsComponent"

export default {
  components: {
    CreateCashDrawerSettings,
    PageTitle,
  },

  data: () => ({
    icon: 'pe-7s-plane icon-gradient bg-tempting-azure',
  })
}
</script>